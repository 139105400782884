import * as yup from 'yup';

import { LoginEditForm } from 'shared/models';

export const loginFormValidationSchema: yup.SchemaOf<LoginEditForm> = yup.object().shape({
	email: yup
		.string()
		.required('Email is required')
		.email('Enter a valid email')
		.max(50, 'Email must be at most 50 characters'),
});
