import React, { FC, useState } from 'react';
import cn from 'classnames';

import { ProgAiOOSContribution } from 'shared/generated-models';
import { Text, TextButton } from 'shared/components/ui';
import { datePeriod } from 'features/candidate/utils';

import styles from './index.module.scss';

interface Props {
	data: ProgAiOOSContribution;
	className?: string;
}

const SHOW_TAGS_COUNT = 5;

export const Repository: FC<Props> = ({ data, className }: Props) => {
	const [isOpen, setIsOpen] = useState(false);

	const handleOpen = () => {
		setIsOpen(open => !open);
	};

	return (
		<div className={cn(styles.repository, className)}>
			<div>
				<div className={styles.repositoryTop}>
					{data.github_url && (
						<a href={data.github_url} className={styles.repositoryLink} target="_blank" rel="noreferrer">
							{data.github_url.substring(19, data.github_url.length)}
						</a>
					)}
					<Text component="p" color="grey">
						{datePeriod(data.first_commit_date, data.last_commit_date)}
					</Text>
				</div>
				{data.description && (
					<Text component="p" color="grey">
						{data.description}
					</Text>
				)}
			</div>
			{data.summary && <Text component="p">{data.summary}</Text>}
			{data.topics && (
				<div className={styles.repositoryContributions}>
					{data.topics.slice(0, SHOW_TAGS_COUNT).map(topic => (
						<Text
							variant="inter/12/medium"
							color="dark_green"
							className={styles.repositoryContribution}
							key={topic}>
							{topic}
						</Text>
					))}
					{isOpen &&
						data.topics.slice(SHOW_TAGS_COUNT, data.topics.length).map(topic => (
							<Text
								variant="inter/12/medium"
								color="dark_green"
								className={styles.repositoryContribution}
								key={topic}>
								{topic}
							</Text>
						))}
					{data.topics.length > 5 && (
						<TextButton onClick={handleOpen}>
							{isOpen ? 'See less' : `+${data.topics.length - SHOW_TAGS_COUNT} more`}
						</TextButton>
					)}
				</div>
			)}
		</div>
	);
};
