import { useNavigate } from 'react-router-dom';
import { Form } from 'antd';
import { FormikProvider, useFormik } from 'formik';

import { loginFormValidationSchema } from 'features/registration';
import { LoginEditForm } from 'shared/models';
import { Button, FormikInput, Icon, Text } from 'shared/components/ui';
import { Empty } from 'shared/components';
import { environment } from 'environments/environment';

import styles from './index.module.scss';

export const ForgotPassword = () => {
	const navigate = useNavigate();

	const handleSubmit = (values: LoginEditForm) => {
		const resend = async () => {
			try {
				const response = await fetch(`${environment.apiUrl}/password-reset/`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						email: values.email!
					})
				});

				if (!response.ok) {
					throw new Error('Failed to sign in');
				}
				navigate('/sign-in');
			} catch (error) {
				console.error('Sign-in error:', error);
			}
		};
		resend();
	};

	const formik = useFormik<LoginEditForm>({
		validationSchema: loginFormValidationSchema,
		enableReinitialize: true,
		validateOnMount: true,
		initialValues: {
			email: ''
		},
		onSubmit: values => handleSubmit(values)
	});

	return (
		<>
			<Icon
				icon="logo-login"
				className={styles.logo}
				size={65}
				onClick={() => {
					navigate('/login');
				}}
			/>
			<Empty
				className={styles.empty}
				title={
					<Text variant="inter/32/semi" component="h1">
						Forgot your password?
					</Text>
				}
				icon="">
				<Text variant="inter/16/regular" color="grey" component="p" className={styles.text}>
					We’ll send you a link to create a new password.
				</Text>
				<FormikProvider value={formik}>
					<Form className={styles.form} layout="vertical" requiredMark={false} name="registration">
						<FormikInput
							className={styles.input}
							name="email"
							label="Email"
							size="large"
							placeholder="Email"
						/>
						<Button
							className={styles.signInButton}
							type="primary"
							block
							onClick={formik.handleSubmit}
							disabled={!formik.isValid}>
							Submit
						</Button>
                        <button
                            onClick={() => {
                                navigate('/sign-in');
                            }}
                            className={styles.link}>
                            <span>Back</span>
                        </button>
					</Form>
				</FormikProvider>
			</Empty>
		</>
	);
};
