import { useNavigate } from 'react-router-dom';

import { Button, Icon, Text } from 'shared/components/ui';
import { Empty } from 'shared/components';
import { useGetCurrentUserQuery } from 'services';
import { environment } from 'environments/environment';

import styles from './index.module.scss';

export const FinishVerification = () => {
	const { data } = useGetCurrentUserQuery();
	const navigate = useNavigate();

	const handleClick = () => {
        const resend = async () => {
			try {
				const response = await fetch(`${environment.apiUrl}/resend-verification/`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
                    body: JSON.stringify({
                        email: data?.email!
                    })
				});

				if (!response.ok) {
					throw new Error('Failed to sign in');
				}
				navigate('/verify-email');
			} catch (error) {
				console.error('Sign-in error:', error);
			}
		};
		resend();
	};

	return (
		<>
			<Icon icon="logo-login" className={styles.logo} size={65} onClick={handleClick} />
			<Empty
				className={styles.empty}
				title={
					<Text variant="inter/32/semi" component="h1">
						Finish your registration!
					</Text>
				}
				icon="verify-email">
				<Text variant="inter/16/regular" color="grey" component="p" className={styles.text}>
					You have not verified your email <span>{data?.email!}</span> yet
				</Text>
				<Button className={styles.button} type="primary" block onClick={handleClick}>
					Send verification link
				</Button>
			</Empty>
		</>
	);
};
