import * as yup from 'yup';

import { PasswordResetForm } from 'shared/models';

export const passwordResetFormValidationSchema: yup.SchemaOf<PasswordResetForm> = yup.object().shape({
	password: yup
		.string()
		.required('Password is required')
		.min(8, 'Password must be at least 8 characters')
		.max(25, 'Password must be at most 25 characters'),
    password1: yup
		.string()
		.required('Password is required')
		.min(8, 'Password must be at least 8 characters')
		.max(25, 'Password must be at most 25 characters'),
});
