import { SignInForm } from 'features/auth';

import styles from './index.module.scss';


export const SignIn = () => {
	return (
		<section className={styles.container}>
			<main className={styles.login}>
				<SignInForm className={styles.login} />
			</main>
			<section className={styles.image}></section>
		</section>
	);
};
