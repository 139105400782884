import { useNavigate } from 'react-router-dom';

import { Button, Icon, Text } from 'shared/components/ui';
import { Empty } from 'shared/components';

import styles from './index.module.scss';

export const CompletePasswordReset = () => {
	const navigate = useNavigate();

	const handleClick = () => {
		navigate('/login');
	};

	return (
		<>
			<Icon icon="logo-login" className={styles.logo} size={65} onClick={handleClick} />
			<Empty
				className={styles.empty}
				title={
					<Text variant="inter/32/semi" component="h1">
						Password Changed!
					</Text>
				}
				icon="verification-complete">
				<Text variant="inter/16/regular" color="grey" component="p" className={styles.text}>
					Your password has been changed successfully.
				</Text>
				<Button className={styles.button} type="primary" block onClick={handleClick}>
					Login
				</Button>
			</Empty>
		</>
	);
};
