import { Icon, Text } from 'shared/components/ui';
import { authLink, microsoftAuthLink } from 'services';

import styles from './index.module.scss';

interface Props {
	text: string;
}

export const GoogleSocialButton = ({ text }: Props) => {
	const submitForm = () => {
		window.location.href = authLink;
	};

	return (
		<button className={styles.wrapper} onClick={submitForm}>
			<Icon className={styles.icon} icon="google-logo" size={32} />
			<Text variant="inter/16/medium" color="white">
				{text}
			</Text>
		</button>
	);
};

export const MicrosoftSocialButton = ({ text }: Props) => {
	const microsoftSubmitForm = () => {
		window.location.href = microsoftAuthLink;
	};

	return (
		<button className={styles.wrapper} onClick={microsoftSubmitForm}>
			<Icon className={styles.icon} icon="microsoft-logo" size={32} />
			<Text variant="inter/16/medium" color="white">
				{text}
			</Text>
		</button>
	);
};
