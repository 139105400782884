import { FC } from 'react';
import { Form, Input as AntInput, InputProps } from 'antd';
import { TextAreaProps } from 'antd/es/input';
import { Field, FieldProps } from 'formik';

import { Icon } from 'shared/components/ui/Icon';

import './index.scss';
import { FormFieldProps, FormikFieldProps } from '../Formik';

type Props = FormFieldProps<Omit<InputProps, 'form'>>;
type TextProps = FormFieldProps<Omit<TextAreaProps, 'form'>>;

export const FormInput: FC<Props> = ({
	field: { onChange, ...field },
	form: { touched, errors },
	label,
	size,
	...otherProps
}: Props) => {
	const errorMsg = touched[field.name] && (errors[field.name] as string);

	const suffix = errorMsg ? <Icon icon="error" /> : <span />;

	return (
		<Form.Item
			label={label}
			help={errorMsg}
			shouldUpdate
			validateStatus={errorMsg ? 'error' : undefined}
			colon={false}>
			<AntInput {...field} {...otherProps} suffix={suffix} onChange={onChange} size={size} />
		</Form.Item>
	);
};

export const FormInputPassword: FC<Props> = ({
	field: { onChange, ...field },
	form: { touched, errors },
	label,
	size,
	...otherProps
}: Props) => {
	const errorMsg = touched[field.name] && (errors[field.name] as string);

	const suffix = errorMsg ? <Icon icon="error" /> : <span />;

	return (
		<Form.Item
			label={label}
			help={errorMsg}
			shouldUpdate
			validateStatus={errorMsg ? 'error' : undefined}
			colon={false}>
			<AntInput.Password {...field} {...otherProps} suffix={suffix} onChange={onChange} size={size} />
		</Form.Item>
	);
};

export const FormTextArea: FC<TextProps> = ({
	field: { onChange, ...field },
	form: { touched, errors, setFieldValue },
	label,
	size,
	...otherProps
}: TextProps) => {
	const errorMsg = touched[field.name] && (errors[field.name] as string);

	const { TextArea } = AntInput;

	const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		const { value } = e.target;
		if (value.trim() === '') {
			setFieldValue(field.name, undefined);
		} else {
			onChange(e);
		}
	};

	return (
		<Form.Item
			label={label}
			help={errorMsg}
			shouldUpdate
			validateStatus={errorMsg ? 'error' : undefined}
			colon={false}>
			<TextArea
				style={{ resize: 'none' }}
				rows={4}
				{...field}
				{...otherProps}
				onChange={handleChange}
				size={size}
			/>
		</Form.Item>
	);
};

type FormikProps = FormikFieldProps<InputProps>;
type FormikTextProps = FormikFieldProps<TextAreaProps>;

export const FormikInput: FC<FormikProps> = ({ name, ...otherProps }: FormikProps) => {
	return (
		<Field name={name}>
			{({ field, form, meta }: FieldProps) => <FormInput field={field} form={form} meta={meta} {...otherProps} />}
		</Field>
	);
};

export const FormikInputPassword: FC<FormikProps> = ({ name, ...otherProps }: FormikProps) => {
	return (
		<Field name={name}>
			{({ field, form, meta }: FieldProps) => <FormInputPassword field={field} form={form} meta={meta} {...otherProps} />}
		</Field>
	);
};

export const FormikTextArea: FC<FormikTextProps> = ({ name, ...otherProps }: FormikTextProps) => {
	return (
		<Field name={name}>
			{({ field, form, meta }: FieldProps) => (
				<FormTextArea field={field} form={form} meta={meta} {...otherProps} />
			)}
		</Field>
	);
};
