import { Navigate, Outlet } from 'react-router-dom';

import { useIsExpired, useIsUnverified } from 'features/auth';

export const ProtectedExpiredRoute = () => {
	const isExpired = useIsExpired();
	const isUnverified = useIsUnverified();

	if (isUnverified) return <Navigate to="/finish-verification" />;

	return isExpired ? <Navigate to="/search" /> : <Outlet />;
};
