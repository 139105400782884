import React, { FC, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Form } from 'antd';
import { FormikProvider, useFormik } from 'formik';
import { useAppDispatch } from 'store';
import { setCredentials } from 'store/credentials';
import cn from 'classnames';

import { Button, FormikInputPassword, Icon, Text } from 'shared/components/ui';
import { signUpFormValidationSchema } from 'features/registration';
import { SignUpEditForm } from 'shared/models';
import { environment } from 'environments/environment';

import styles from './index.module.scss';

interface Props {
	className?: string;
}

export const SignUpForm: FC<Props> = ({ className }: Props) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [searchParams] = useSearchParams();
	const [error, setError] = useState<string | null>(null);

	const queryEmail = searchParams.get('email') || '';

	const handleSubmit = async (values: SignUpEditForm) => {
		try {
			if (values.password !== values.password1) {
				setError('Password do not match!');
				return;
			}
			const response = await fetch(`${environment.apiUrl}/register/`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					email: values.email,
					password: values.password,
					password2: values.password1
				})
			});

			if (!response.ok) {
				setError('Something went wrong. Try again');
				throw new Error('Failed to sign in');
			}
			const data = await response.json();

			const accessToken = data.access;
			const refreshToken = data.refresh;

			dispatch(setCredentials({ accessToken, refreshToken }));

			navigate('/registration/form', {
				replace: true
			});
		} catch (error) {
			console.error('Sign-in error:', error);
		}
	};

	const formik = useFormik<SignUpEditForm>({
		validationSchema: signUpFormValidationSchema,
		enableReinitialize: true,
		validateOnMount: true,
		initialValues: {
			email: queryEmail,
			password: '',
			password1: ''
		},
		onSubmit: values => handleSubmit(values)
	});

	const handleClick = () => {
		navigate('/login');
	};

	return (
		<div className={cn(styles.login, className)}>
			<Icon icon="logo-login" className={styles.logo} size={65} onClick={handleClick} />
			<div className={styles.card}>
				<div className={styles.cardTop}>
					<Text variant="inter/24/semi">Create a password</Text>
					<FormikProvider value={formik}>
						<Form className={styles.form} layout="vertical" requiredMark={false} name="registration">
							<FormikInputPassword
								className={styles.input}
								name="password"
								label="Password"
								size="large"
								placeholder="Password"
							/>
							<FormikInputPassword
								className={styles.input}
								name="password1"
								label="Confirm Password"
								size="large"
								placeholder="Repeat password"
							/>
							{error && (
								<div style={{ marginBottom: '16px' }}>
									<Text variant="inter/14/regular" color="red">
										{error}
									</Text>
								</div>
							)}
							<div className={styles.buttons}>
								<Button
									className={styles.loginButton}
									type="primary"
									block
									onClick={formik.handleSubmit}
									disabled={!formik.isValid}>
									Continue
								</Button>
								<Text variant="inter/14/regular" color="grey">
									Already have an account?{' '}
									<button
										onClick={() => {
											navigate('/sign-in');
										}}
										className={styles.link}>
										<span>Sign in</span>
									</button>
								</Text>
							</div>
						</Form>
					</FormikProvider>
				</div>
			</div>
			<span></span>
		</div>
	);
};
