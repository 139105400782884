import React, { CSSProperties, FC, ReactNode } from 'react';
import cn from 'classnames';

import styles from './index.module.scss';

export type TextFont = 'inter' | 'grotesk' | 'mono';
export type TextSize = '32' | '28' | '26' | '24' | '22' | '20' | '18' | '16' | '15' | '14' | '13' | '12';
export type TextWeight = 'bold' | 'semi' | 'medium' | 'regular';
export type TextVariant = `${TextFont}/${TextSize}/${TextWeight}`;
export type TextColor =
	| 'green'
	| 'black'
	| 'grey'
	| 'light_blue'
	| 'blue'
	| 'blue_new'
	| 'dark_blue'
	| 'dark_green'
	| 'white'
	| 'red'
	| 'light_grey'
	| 'dark_grey'
	| 'darker_grey'
	| 'orange';

type Components = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';

interface Props {
	className?: string;
	children?: ReactNode;
	component?: Components;
	variant?: TextVariant;
	color?: TextColor;
	lineHeight?: number;
	paragraph?: boolean;
	style?: CSSProperties;
}

export const Text: FC<Props> = ({
	className,
	children,
	color = 'black',
	component,
	paragraph,
	lineHeight,
	variant = 'inter/14/regular',
	...otherProps
}: Props) => {
	const Component = component || (paragraph ? 'p' : 'span');

	const [font, size, weight] = variant.split('/');

	return (
		<Component
			{...otherProps}
			className={cn(
				styles.text,
				styles[`color_${color}`],
				styles[`font_${font}`],
				styles[`size_${size}`],
				styles[`weight_${weight}`],
				className
			)}
			style={{ lineHeight: `${lineHeight}%` }}>
			{children}
		</Component>
	);
};
