import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Icon, Text } from 'shared/components/ui';
import { Empty } from 'shared/components';
import { useGetCurrentUserQuery } from 'services';
import { logout } from 'services/storage.service';

import styles from './index.module.scss';

export const VerifyEmail = () => {
	const { data } = useGetCurrentUserQuery();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const isNew = searchParams.get('is_new');

	const handleVerifyEmail = () => {
		console.log('verfiy clicked');
	};

	useEffect(() => {
		const cleanup = () => {
			logout();
		};

		const handleUnload = () => {
			cleanup();
		};

		const handlePopState = () => {
			cleanup();
		};

		window.addEventListener('beforeunload', handleUnload);
		window.addEventListener('popstate', handlePopState);

		return () => {
			window.removeEventListener('beforeunload', handleUnload);
			window.removeEventListener('popstate', handlePopState);
			cleanup();
		};
	}, []);

	const handleClick = () => {
		navigate('/login');
	};

	return (
		<>
			<Icon icon="logo-login" className={styles.logo} size={65} onClick={handleClick} />
			<Empty
				className={styles.empty}
				title={
					<Text variant="inter/32/semi" component="h1">
						Verify your email
					</Text>
				}
				icon="verify-email">
				<div className={styles.body}>
					<div className={styles.column}>
						<Text variant="inter/16/regular" color="grey" component="p" className={styles.text}>
							We've sent an email to
						</Text>
						<Text variant="inter/16/bold" component="p" className={styles.text}>
							{data?.email!}
						</Text>
					</div>
					<Text variant="inter/16/regular" component="p" className={styles.text}>
						Please check your email and click on the link provided to verify your address.
					</Text>
					<Text variant="inter/16/regular" component="p" className={styles.text}>
						If you don’t see our email, please check your spam.
					</Text>
				</div>
				<button onClick={handleVerifyEmail}>
					<Text variant="inter/16/medium" className={styles.link}>
						Send again
					</Text>
				</button>
			</Empty>
		</>
	);
};
