import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppDispatch } from 'store';
import { setCredentials } from 'store/credentials';
import { Button, Icon, Text } from 'shared/components/ui';
import { Empty } from 'shared/components';
import { environment } from 'environments/environment';

import styles from './index.module.scss';

export const CompleteVerification = () => {
	const [state, setState] = useState<'waiting' | 'complete' | 'error'>('waiting');
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const uid = searchParams.get('uid') || '';
	const token = searchParams.get('token') || '';

	useEffect(() => {
		const verify = async () => {
			try {
				const response = await fetch(`${environment.apiUrl}/verify-email/${uid}/${token}/`, {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json'
					}
				});

				if (!response.ok) {
					setState('error');
					throw new Error('Failed to sign in');
				}
				const data = await response.json();
				const accessToken = data.access;
				const refreshToken = data.refresh;
				dispatch(setCredentials({ accessToken, refreshToken }));
				setState('complete');
			} catch (error) {
				setState('error');
				console.error('Sign-in error:', error);
			}
		};
		verify();
	}, []);

	const handleClick = () => {
		navigate('/login');
	};

	const handleSuccess = () => {
		navigate('/search');
	}

	const handleVerifyEmail = () => {
		console.log('verfiy clicked');
	};

	return (
		<>
			<Icon icon="logo-login" className={styles.logo} size={65} onClick={handleClick} />
			{state === 'waiting' && (
				<Empty
					className={styles.empty}
					title={
						<Text variant="inter/32/semi" component="h1">
							Verifying your email...
						</Text>
					}
					icon="verify-email"></Empty>
			)}
			{state === 'complete' && (
				<Empty
					className={styles.empty}
					title={
						<Text variant="inter/32/semi" component="h1">
							Verification Complete!
						</Text>
					}
					icon="verification-complete">
					<Button className={styles.button} type="primary" block onClick={handleSuccess}>
						Start Hiring
					</Button>
				</Empty>
			)}
			{state === 'error' && (
				<Empty
					className={styles.empty}
					title={
						<Text variant="inter/32/semi" component="h1">
							Something went wrong
						</Text>
					}
					icon="error-stack">
					<button onClick={handleVerifyEmail}>
						<Text variant="inter/16/medium" className={styles.link}>
							Send again
						</Text>
					</button>
				</Empty>
			)}
		</>
	);
};
