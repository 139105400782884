import { SectionName } from 'features/candidate/utils';

export const normalizeArray = (array: Array<any>, sectionName: SectionName): Array<any> => {
	if (sectionName === 'oss_contribution') {
		const sortedData = [...array].sort((a, b) => (b.contribution_weight || 0) - (a.contribution_weight || 0));
		return sortedData;
	}

	if (sectionName !== 'experience') return array;

	const arrayCopy = [...array];

	const sortedData = arrayCopy.sort((a, b) => {
		const parseDate = (dateString: string) => (dateString ? new Date(dateString) : null);

		const aEndDate = a.current === 1 ? null : parseDate(a.endDate);
		const bEndDate = b.current === 1 ? null : parseDate(b.endDate);

		if (a.current === 1 && b.current !== 1) {
			return -1;
		}

		if (a.current !== 1 && b.current === 1) {
			return 1;
		}

		if (aEndDate && bEndDate) {
			const endDateComparison = bEndDate.getTime() - aEndDate.getTime();
			if (endDateComparison !== 0) {
				return endDateComparison;
			}
		}

		const aStartDate = a.startDate ? parseDate(a.startDate)?.getTime() : 0;
		const bStartDate = b.startDate ? parseDate(b.startDate)?.getTime() : 0;

		return (bStartDate || 0) - (aStartDate || 0);
	});

	return sortedData;
};
