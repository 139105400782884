import * as yup from 'yup';

import { SignInEditForm } from 'shared/models';

export const signInFormValidationSchema: yup.SchemaOf<SignInEditForm> = yup.object().shape({
	email: yup
		.string()
		.required('Email is required')
		.email('Enter a valid email')
		.max(50, 'Email must be at most 50 characters'),
	password: yup
		.string()
		.required('Password is required')
		.min(8, 'Password must be at least 8 characters')
		.max(25, 'Password must be at most 25 characters'),
});
