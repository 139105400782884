import { Text } from 'shared/components/ui';

import styles from './index.module.scss';

export const Divider = () => {
	return (
		<div className={styles.dividerWrapper}>
			<div className={styles.divider}></div>
			<Text variant="inter/16/regular" color="grey">
				or
			</Text>
			<div className={styles.divider}></div>
		</div>
	);
};
